import React from 'react'
import {useStaticQuery, graphql } from 'gatsby'
import { formatPostDate } from '../../services/post.service'
import { Last } from 'react-bootstrap/esm/PageItem'

const BlogNextPrev = ({currentArticleId, language}) => {
  const post = useStaticQuery(graphql`
  query nextPrevPost {
    allPostEN: allWpPost(filter: {language: {code: {eq: EN}}}) {
      nodes {
        id
        date(formatString: "DD MM YYYY")
        title
        uri
        author {
          node {
            avatar {
              url
            }
            name
            uri
          }
        }
      }
    }
    allPostFR: allWpPost(filter: {language: {code: {eq: FR}}}) {
      nodes {
        id
        date(formatString: "DD MM YYYY")
        title
        uri
        author {
          node {
            avatar {
              url
            }
            name
            uri
          }
        }
        
      }
    }
  }`)
  let posts
  switch(language){
    case "EN":
       posts = post.allPostEN.nodes
      break
    default : 
       posts = post.allPostFR.nodes
  }  

  
  const index = posts.findIndex(post => post.id === currentArticleId)
  const currentPost = posts[index]
  const postLength = posts.length;
  const previousPost = posts[(index+postLength-1)%postLength];
  const nextPost = posts[(index+1)%postLength];
  return (
    <>
      <div className="post-author">
            <div className="author-img">
                <img src={currentPost.author.node.avatar.url}/>
            </div>
            <div className="author-content">
                <h5>  About <a href={""}> {currentPost.author.node.name}</a></h5>
            </div>
      </div>
      <div className="post-pagination">
      <div className="prev-post">
         {index > 0 && (
          <>
            <div className="prev-post-title">
              <span>
                <i className="icon-nav-next">
                </i> 
                PREVIOUS POST
                
              </span>
        </div>
        <a href={`/blog` + previousPost.uri}>
          <div className="pagi-text">
            <h5 className="prev-title">{previousPost.title}</h5>
          </div>
        </a>
          </>
         )}
    </div>
      {index < posts.length -1  && (
        <>
          <div className="next-post">
            <div className="next-post-title">
                <span>
                    NEXT POST
                    <i className="icon-nav-next">
                    </i>
                    
                </span>
              </div>
              <a href={`/blog` + nextPost.uri}>
                <div className="pagi-text">
                  <h5 className="next-title">{nextPost.title}</h5>
                </div>
              </a>
        </div>
        </>
      )}   
    </div>
    </>
    
  )
}

export default BlogNextPrev