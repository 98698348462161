import React from "react"
import {Container, Row, Col} from "react-bootstrap"
import Slider from "react-slick"
import "../../templates/blog/blog.scss"
import { useStaticQuery, graphql, Link } from "gatsby"
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import * as moment from "moment"

const BlogMightLike = ({ language, categories, excludePost }) => {

    const data = useStaticQuery(graphql`
        query PostCategoryList {
            WPPostsEN: allWpPost(
                filter: { language: { code: { eq: EN } } }
            ) {
                nodes {
                  id
                  date
                  title
                  excerpt
                  featuredImage {
                    node {
                      gatsbyImage(height: 1080)
                    }
                  }
                  uri
                  categories {
                    nodes {
                      id
                    }
                  }
                }
            }
            WPPostsFR: allWpPost(
                filter: { language: { code: { eq: FR } } }
            ) {
                nodes {
                  id
                  date
                  title
                  excerpt 
                  featuredImage {
                    node {
                      gatsbyImage(height: 1080)
                    }
                  }
                  uri
                  categories {
                    nodes {
                      id
                    }
                  }
                }
            }
        }
    `)

    let alsoLike
    switch (language) {
        case "FR":
            alsoLike = data.WPPostsFR.nodes
            break
        default:
            alsoLike = data.WPPostsEN.nodes
    }

    const excludedCategoryIds = categories.map(category => category.id);

    // Post with same category as current post
    const postsWithSameCat = alsoLike.filter(post => {
        const postCategoryIds = post.categories.nodes.map(node => node.id);
        return excludedCategoryIds.some(id => postCategoryIds.includes(id));
      });

    // Remove current post from list
    const filteredPosts = postsWithSameCat.filter(post => post.id !== excludePost)

    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    arrows: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: "50px"
                }
            }
        ]
    }

    return (
       filteredPosts.length > 0 && (
        <section className="blk-blog-mightLike">
            <Row>
                <Col>
                    <div className="post-title">
                        <h4>
                            {language == "FR" ? "Vous aimerez peut-être aussi" : "You may also like"}
                        </h4>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Slider className="youMayLike_Slider mobileSlider" {...settings}>
                        {filteredPosts.map(post => (
                                    <div className="blogItem">
                                        <Link to={"/blog" + post.uri}>
                                            <GatsbyImage
                                                image={getImage(
                                                    post?.featuredImage?.node?.gatsbyImage
                                                )}
                                            />
                                        </Link>
                                        <h5>
                                        <Link to={"/blog" + post.uri}>{post.title}
                                        </Link>
                                        </h5>
                                        <span>{moment(post.date).format("Do MMMM YYYY")}</span>
                                    </div>
                        ))}
                    </Slider>
                </Col>
            </Row>
        </section>
        )
    )
}

export default BlogMightLike;