import React , { useState }from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { graphql, Link } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"
import HTMLReactParser from "html-react-parser"
import { Col, Container, Row } from "react-bootstrap"
import LayoutBlog from "../../components/blog/layoutBlog"
import Title from "../../components/common/title"
import BlogNextPrev from "./blog-nextPrev"
import Breadcrumbs from "../../components/common/breadcrumbs/breadcrumbs"
import { formatPostDate } from "../../services/post.service"
import { getSrc } from "gatsby-plugin-image"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share"
import { setSeoData } from "../../services/seo.service"
import OffresSpeciales from "../../components/blog/vr-offres-speciales-banner.jpg";
import SpecialOffers from "../../components/blog/vr-special-offers-banner.jpg";
import BlogMightLike from "../../components/blog/alsoLikeBlog";

export default function BlogContent({ data: { wpPost }, pageContext }) {

  const isSSR = typeof window === "undefined"
  const intl = useIntl()
  const {
    title, content, featuredImage, seo, date,
    tags: {
      nodes: tagLists
    },
    categories: {
      nodes: categoriesList
    },
    author: {
      node: authorName
    }
  } = wpPost

  const seoData = setSeoData({
    lang: pageContext.localeBlog.toLowerCase(),
    title: title,
    field_seo: {
      description: seo.metaDesc,
      title: seo.title,
      og_description: seo.opengraphDescription,
      og_image: seo.opengraphImage
        ? process.env.GATSBY_URL + seo.opengraphImage.publicUrl
        : "",
      og_locale: pageContext.localeBlog.toLowerCase(),
      og_title: seo.opengraphTitle,
      og_type: seo.opengraphType,
    },
    image: seo.opengraphImage
      ? process.env.GATSBY_URL + seo.opengraphImage.publicUrl
      : "",
  })

  const shareUrl = typeof window !== "undefined" ? window.location.href : ""

  const [isOpened, setIsOpened] = useState(false)

  function toggleSocialShares() {
    setIsOpened(wasOpened => !wasOpened)
  }

  return (
    <Layout pageContext={pageContext} pageTitle={title} pageClass="pageBlog">
      <Seo data={seoData} />
      {!isSSR && (
        <React.Suspense fallback="loading.... ">
          <section className="mainBanner mainBannerBlog">
            <div
              style={{
                backgroundImage:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 75%), url(" +
                    featuredImage?.node?.sourceUrl +
                  ")",
              }}
            >
              <Container>
                <Row className="justify-content-center alignCenter">
                  <Col md={10} xs={12}>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
          <Breadcrumbs pageTitle={title} localeBlog={pageContext.localeBlog} />
          <LayoutBlog
            language={pageContext.localeBlog}
            templateType="innerBlog"
          >
            <Container>
              <div className="blogArticleCategories">
                {categoriesList.length > 0 &&
                    categoriesList.map(
                        (category, index) => {
                          if (
                              index !== categoriesList.length - 1
                          ){
                            return <Link to={"/blog" + category.uri} key={index} className="caption square">{category.name}</Link>
                          } else {
                            return <Link to={"/blog" + category.uri} key={index} className="caption">{category.name}</Link>
                          }
                        }
                    )
                }
              </div>
              <div className="blkHeading">
                <Title
                  heading={1}
                  size={2}
                  extraClass="mainTitle"
                  text={title}
                />
                <div className="textAuthorDate">
                  <span className="authorName"> {pageContext.localeBlog == "FR" ? "posté par" : "posted by" } {authorName.name}</span>
                  <span className="dateInfo">{formatPostDate(date, pageContext.localeBlog.toLowerCase())}</span>
                </div>
              </div>
              <div className="blk-wysiwig">{HTMLReactParser(content)}</div>
              <div className="tags-share-box">
                <span>Tags: </span>
                {tagLists.map((tag, index) => (
                  <>
                    <Link to={"/blog" + tag.uri} key={index}>{tag.name}</Link>{index !== tagLists.length - 1 && <span>, </span>}
                  </>
                ))}
              </div>

              <BlogNextPrev currentArticleId={pageContext.id} language={pageContext.localeBlog}/>
              <div className="blk-shareArticle">
                <h2 onClick={toggleSocialShares}>
                  {intl.formatMessage({
                    id: "page.room.share",
                  })}
                  <i className="icon-share"></i>
                </h2>
                {isOpened && (
                  <div>
                  <FacebookShareButton url={shareUrl} quote={title}>
                    <FacebookIcon size={40} round />
                  </FacebookShareButton>
                  <TwitterShareButton url={shareUrl} quote={title}>
                    <TwitterIcon size={40} round />
                  </TwitterShareButton>
                  <PinterestShareButton url={shareUrl} quote={title}>
                    <PinterestIcon size={40} round />
                  </PinterestShareButton>
                  <WhatsappShareButton url={shareUrl} quote={title}>
                    <WhatsappIcon size={40} round />
                  </WhatsappShareButton>
                  <LinkedinShareButton url={shareUrl} quote={title}>
                    <LinkedinIcon size={40} round />
                  </LinkedinShareButton>
                  <TelegramShareButton url={shareUrl} quote={title}>
                    <TelegramIcon size={40} round />
                  </TelegramShareButton>
                  <EmailShareButton url={shareUrl} quote={title}>
                    <EmailIcon size={40} round />
                  </EmailShareButton>
                </div>
                )}

              </div>
              <BlogMightLike language={pageContext.localeBlog} categories={categoriesList} excludePost={pageContext.id} />
            </Container>
          </LayoutBlog>
        </React.Suspense>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      date
      title
      content
      uri
      featuredImage {
        node {
          gatsbyImage(width: 1920, formats: WEBP)
          publicUrl
          sourceUrl
        }
      }
      categories {
          nodes {
            id
            name
            uri
          }
      }
      author {
        node {
          name
        }
      }
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphType
        opengraphImage {
          publicUrl
        }
      }
      tags {
        nodes {
          name
          uri
        }
      }
    }
  }
`
